/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

*{
    font-family: Poppins,"Arimo",system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}

.p-10{
    padding: 10px;
}

.ml5{
    margin-left: 5px;
}

.betting-category{
    border-radius: 15px;
}

.btn-fill-primary {
    color: #fff;
    background-color: #fabd1f;
    border-color: #fabd1f;
}

.btn-fill-sec,.btn-fill-sec:active {
    color: #fff;
    background-color: #f64982 !important;
    border-color: #f6276c !important;
}

.form-control:focus {
    border-color: #fabd1f;
    box-shadow: 0 0 0 0.25rem rgb(252, 244, 87)
}

.btn-yellow ,.btn-yellow:active{
    color: #fff !important;
    background-color: #fabd1f !important;
    border-color: #fabd1f !important;
}

.login-popup{
    --height:80%;
    --width:90%;
    --border-radius: 10px;
}

.font-orange{
    color: #ff9900;
}